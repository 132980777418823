<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="800"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title>
        {{ $t('invoices.general.edit_invoice_no_counter') }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="invoiceCounter"
              :error-messages="errors.no"
              :label="$t('invoices.labels.counter')"
              type="number"
              @input="formMixin_clearErrors('no')"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click.native="goBack">
          {{ $t('general.controls.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="updateInvoiceCounter"
          :disabled="saving"
          :loading="saving"
        >
          {{ $t('general.controls.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import formMixin from '@/mixins/form-mixin';
import invoiceService from '@/api/invoice-service';
import { openSnackbar } from '@/util/event-bus';
import { mapErrorsToInputs } from '@/util/forms';

export default {
  name: 'InvoiceNumberCounterDialog',

  mixins: [formMixin],

  data() {
    return {
      invoiceCounter: '',
      errors: {},
    };
  },

  computed: {
    saving() {
      return this.$store.getters.loading['put:api/finances/invoices/settings/last-invoice-no'];
    },
  },

  methods: {
    async updateInvoiceCounter() {
      try {
        await invoiceService.updateNumberCounter({ no: this.invoiceCounter });
        openSnackbar(this.$t('invoices.invoice_counter_updated'));
        this.goBack();
      } catch (e) {
        this.errors = mapErrorsToInputs(e);
      }
    },

    goBack() {
      this.$router.push(this.$route.meta.getBackRoute());
    },
  },
};
</script>
