<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="800"
    scrollable
    persistent
  >
    <InvoiceForm
      :invoice="newInvoice"
      :errors="invoiceValidationErrors"
      :disabled="$store.getters.loading['post:api/finances/invoices']"
      @clear:errors="CLEAR_INVOICE_VALIDATION_ERRORS"
      @cancel="onCancel"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import InvoiceForm from '@/components/forms/InvoiceForm';

export default {
  name: 'CreateInvoice',

  components: { InvoiceForm },

  computed: {
    ...mapState('invoices', ['newInvoice', 'invoiceValidationErrors', 'invoiceFilterParams']),
  },

  created() {
    this.SET_INVOICE_VALIDATION_ERRORS({});
  },

  methods: {
    ...mapActions('invoices', ['storeInvoice']),
    ...mapMutations('invoices', [
      'SET_NEW_INVOICE',
      'SET_INVOICE_VALIDATION_ERRORS',
      'CLEAR_INVOICE_VALIDATION_ERRORS',
    ]),

    onCancel(invoice) {
      this.SET_NEW_INVOICE(invoice);
      this.goBack();
    },

    onSave(invoice) {
      this.storeInvoice(invoice).then((createdInvoice) => {
        this.$emit('invoice-created', createdInvoice);
        this.goBack(createdInvoice.id);
      });
    },

    goBack(invoiceId) {
      this.$router.push(this.$route.meta.getBackRoute(invoiceId));
    },
  },
};
</script>
